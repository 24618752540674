<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">ADD USER</p>
    </div>
    <div class="dialog-content" style="overflow-y: auto;">
      <b-container class="mt-3" fluid>
        <b-form @submit="onSubmit" v-if="custom.status === 'pending'">
          <b-form-group label="Nickname: "
                        :label-cols="3"
                        label-for="nickname"
                        horizontal>
            <b-form-input id="nickname"
                          type="text"
                          aria-describedby="nickname-input"
                          placeholder="Enter Nickname"
                          autocomplete="off"
                          @input="$v.form.nickname.$touch()"
                          v-model.trim="$v.form.nickname.$model"
                          :state="!$v.form.nickname.$error">
            </b-form-input>
            <b-form-invalid-feedback id="nickname-input">
              Nickname is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="First Name: "
                        :label-cols="3"
                        label-for="firstName"
                        horizontal>
            <b-form-input id="firstName"
                          type="text"
                          aria-describedby="firstName-input"
                          placeholder="Enter First Name"
                          autocomplete="off"
                          @input="$v.form.firstName.$touch()"
                          v-model.trim="$v.form.firstName.$model"
                          :state="!$v.form.firstName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="firstName-input">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Name: "
                        :label-cols="3"
                        label-for="lastName"
                        horizontal>
            <b-form-input id="lastName"
                          type="text"
                          aria-describedby="lastName-input"
                          placeholder="Enter Last Name"
                          autocomplete="off"
                          @input="$v.form.lastName.$touch()"
                          v-model.trim="$v.form.lastName.$model"
                          :state="!$v.form.lastName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="lastName-input">
              Last Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Email: "
                        :label-cols="3"
                        label-for="email"
                        horizontal>
            <b-form-input id="email"
                          type="email"
                          placeholder="Enter Email"
                          aria-describedby="email-input"
                          autocomplete="nope"
                          v-model.trim="$v.form.email.$model"
                          @input="$v.form.email.$touch()"
                          :state="!$v.form.email.$error">
            </b-form-input>
            <b-form-invalid-feedback id="email-input">
              <span v-if="!$v.form.email.required">Email is required.</span>
              <span v-if="!$v.form.email.email && $v.form.email.required">
                This requires a valid email address.
              </span>
              <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
                      $v.form.email.required">
                Email is already registered.
                </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Password: "
                        :label-cols="3"
                        label-for="password"
                        horizontal>
            <b-form-input id="password"
                          type="password"
                          placeholder="Enter Password"
                          aria-describedby="password-input"
                          autocomplete="new-password"
                          @input="$v.form.password.$touch()"
                          v-model.trim="$v.form.password.$model"
                          :state="!$v.form.password.$error">
            </b-form-input>
            <b-form-invalid-feedback id="password-input">
              <span v-if="!$v.form.password.required">
                Password is required.
              </span>
              <span v-if="!$v.form.password.minLength">
                Password must have at least
                {{ $v.form.password.$params.minLength.min }} letters.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Confirm Password: "
                        :label-cols="3"
                        label-for="repeatPassword"
                        horizontal>
            <b-form-input id="repeatPassword"
                          type="password"
                          placeholder="Enter Password"
                          aria-describedby="repeatPassword-input"
                          autocomplete="off"
                          @input="$v.repeatPassword.$touch()"
                          v-model.trim="$v.repeatPassword.$model"
                          :state="!$v.repeatPassword.$error">
            </b-form-input>
            <b-form-invalid-feedback id="repeatPassword-input">
              <span v-if="!$v.repeatPassword.sameAsPassword">
                Passwords must be identical.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- <b-form-group horizontal>
            <label for="premium_type" class="p-1"><span>Premium :</span></label>
            <toggle-button class="large-font"
                           id="premium_type"
                           :labels="{checked: 'True', unchecked: 'False'}"
                           :width="100"
                           :height="50"
                           v-model="form.premium_type"/>
            <label for="premium_type" class="p-1"><span>Status :</span></label>
            <toggle-button class="large-font"
                           id="premium_type"
                           :labels="{checked: 'Activated',
                             unchecked: 'Deactivated'}"
                           :width="150"
                           :height="50"
                           v-model="form.status"/>
          </b-form-group> -->
          <b-form-group label="Role: "
                        :label-cols="3"
                        label-for="role"
                        horizontal>
            <b-form-select id="role"
                           aria-describedby="role-input"
                           :options="options"
                           :state="!$v.form.userType.$error"
                           v-model.trim="$v.form.userType.$model"
                           @input="$v.form.userType.$touch()" />
            <b-form-invalid-feedback id="role-input">
              Please Select a Role.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Grade Level: "
                        :label-cols="3"
                        label-for="gradeLevel"
                        horizontal
                        v-show="form.userType === 1">
            <b-form-select id="gradeLevel"
                           autocomplete="off"
                           @input="$v.form.gradeLevel.$touch()"
                           v-model.trim="$v.form.gradeLevel.$model"
                           :state="!$v.form.gradeLevel.$error"
                           :options="gradeLevels" />
            <b-form-invalid-feedback id="school">
              <span v-if="!$v.form.gradeLevel.required">
                Please Select a grade level.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="School: "
                        label-for="school"
                        :label-cols="3"
                        horizontal
                        v-show="form.userType === 1 || form.userType === 3 ||
                          form.userType === 4">
            <school-widget @changed="setSchool"
                           :allowAdd="false"
                           :classes="'add-user-school'"
                           ref="schoolWidget" />
            <b-form-invalid-feedback id="school">
              <span v-if="!$v.form.schoolId.required">
                School is required.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      :disabled="$v.$invalid"
                      variant="outline-success">
              Submit
            </b-button>
            <b-button class="text-center ml-1 "
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>

        <!-- Send Activation Email -->
        <div v-else>
          <b-row class="justify-content-md-center" style="padding: 3rem 0;">
            <b-col col lg="2"></b-col>
            <b-col cols="12" md="auto">
              <span class='fa-stack fa-4x reg_violet'>
                  <i class='fa fa-circle fa-stack-2x current-step'></i>
                  <i class='fa fa-envelope fa-stack-1x fa-inverse'></i>
              </span>
            </b-col>
            <b-col col lg="2"></b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col col lg="2"></b-col>
            <b-col cols="12" md="auto">
              <p class='message medium text-center' v-html="custom.message"></p>
              <div class="d-flex justify-content-center"
                   v-if="custom.status === 'failed'">
                <b-button class="text-center"
                          type="submit"
                          variant="outline-primary"
                          @click="sendActivationEmail">
                  Resend Activation Email
                </b-button>
                <b-button class="text-center ml-1 "
                          variant="outline-danger"
                          @click="closeModal">
                  Cancel
                </b-button>
              </div>
            </b-col>
            <b-col col lg="2"></b-col>
          </b-row>
        </div>

        <!-- Loading -->
        <loading :active.sync="isLoading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');

  import md5 from 'md5';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {
    required,
    minLength,
    email,
    sameAs,
  } from 'vuelidate/lib/validators';

  export default {
    name  : 'AddUser',
    props : ['data'],
    data() {
      return {
        isLoading : false,
        form      : {
          nickname   : null,
          email      : null,
          firstName  : null,
          lastName   : null,
          status     : true,
          userType   : null,
          schoolId   : null,
          gradeLevel : null,
          password   : null,
        },
        repeatPassword : null,
        userID         : null,
        uniqueEmail    : true,
        custom         : {
          status  : 'pending',
          message : null,
        },
        options : [ {
          value    : null,
          text     : 'Please select an option',
          disabled : true,
        }, {
          value : 0,
          text  : 'Administrator',
        }, {
          value : 1,
          text  : 'Kid',
        }, {
          value : 2,
          text  : 'Parent',
        }, {
          value : 3,
          text  : 'Educator',
        } ],
        gradeLevels : [ {
          value    : null,
          text     : 'Grade Level',
          disabled : true,
        }, {
          value : '0',
          text  : 'Kindergarten',
        }, {
          value : '1',
          text  : '1st Grade',
        }, {
          value : '2',
          text  : '2nd Grade',
        }, {
          value : '3',
          text  : '3rd Grade',
        }, {
          value : '4',
          text  : '4th Grade',
        }, {
          value : '5',
          text  : '5th Grade',
        }, {
          value : '6',
          text  : '6th Grade',
        }, {
          value : '7',
          text  : '7th Grade',
        }, {
          value : '8',
          text  : '8th Grade',
        }, {
          value : '9',
          text  : '9th Grade',
        }, {
          value : '10',
          text  : '10th Grade',
        }, {
          value : '11',
          text  : '11th Grade',
        }, {
          value : '12',
          text  : '12th Grade',
        } ],
      };
    },
    computed : {
      formEmail : function () {
        return this.form.email;
      },
      formUserType : function () {
        return this.form.userType;
      },
    },
    components : {
      Loading,
      SchoolWidget,
    },
    watch : {
      formEmail : function () {
        this.uniqueEmail = true;
        this.checkEmail();
      },
      formUserType : function () {
        this.form.gradeLevel = null;
        this.$v.form.gradeLevel.$reset();
        this.$refs.schoolWidget.resetSelectedSchool();
      },
    },
    methods : {

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Add User
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;
        this.$http.post('api/user', {
          'nickname'   : this.form.nickname,
          'firstname'  : this.form.firstName,
          'lastname'   : this.form.lastName,
          'email'      : this.form.email,
          'password'   : this.form.password,
          'userType'   : this.form.userType,
          'schoolId'   : this.form.schoolId,
          'gradeLevel' : this.form.gradeLevel,
        }).then(() => {
          // Get user id via email
          this.getUserID(this.form.email);

          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Added ' + this.form.firstName + ' ' + this.form.lastName +
              ' successfully!',
          });

        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Close 'Add User' Modal
       */
      closeModal() {
        this.$emit('modalClose');
        this.$emit('close');
      },

      /**
       * Get User ID via Email
       * @param {string} email User's registered email address
       */
      getUserID(email) {
        this.$http.get('api/user/id', {
          params : {
            email : email,
          },
        }).then(response => {
          this.userID = response.data.user_id;

          // Send email
          this.sendActivationEmail();
        }).catch(() => {});
      },

      /**
       * Send Activation Email to User
       */
      sendActivationEmail() {
        this.custom.status = 'sending';
        this.custom.message = 'Sending an Activation Email to ' +
          this.form.firstName + ' ' + this.form.lastName;

        const baseUrl = process.env.VUE_APP_BASE_URL;
        const activationUrl = baseUrl + '/activate/' + this.userID + '/' +
          md5(this.form.email);

        this.$http.post('auth/sendActivationEmail', {
          'to'   : this.form.email,
          'url'  : activationUrl,
          'name' : this.form.firstName,
        }).then(() => {
          // Successfuly Sent an Email Verification
          this.custom.message = 'Activation Email Sent Successfully!';

          const that = this;
          setTimeout(() => {
            that.isLoading = false;
            that.custom.status = 'success';
            that.closeModal();
          }, 2000);
        }).catch(() => {
          this.isLoading = false;
          this.custom.status = 'failed';
          this.custom.message = 'Failed to Send an Activation Email! <br>';
        });
      },

      /**
       * Set School for manual input
       * @param data
       */
      setSchool(data) {
        this.form.schoolId = data;
      },
    },
    validations() {
      return {
        form : {
          nickname  : { required },
          firstName : { required },
          lastName  : { required },
          userType  : { required },
          schoolId  : (this.form.userType === 1 || this.form.userType === 3 ||
            this.form.userType === 4) ? { required } : {},
          gradeLevel : (this.form.userType === 1) ? { required } : {},
          email      : {
            email,
            required,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
          password : {
            required,
            minLength : minLength(5),
          },
        },
        repeatPassword : {
          required,
          sameAsPassword : sameAs(function () {
            return this.form.password;
          }),
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/userlist/add-user";
</style>
